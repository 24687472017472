/* eslint-disable import/no-cycle */
import Vue from 'vue';

const MODULE_ROUTE = 'payments';

export default {
  getCountries() {
    return Vue.axios.get(`${MODULE_ROUTE}/countries`).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  getPlans() {
    return Vue.axios.get('plans').catch((error) => {
      throw new Error(`${error}`);
    });
  },
  createSubscription(data) {
    return Vue.axios.post('subscriptions', data).catch((error) => {
      throw new Error(`${error}`);
    });
  },
  cancelSubscription() {
    return Vue.axios.post('subscriptions/cancel').catch((error) => {
      throw new Error(`${error}`);
    });
  },
};
