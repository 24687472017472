<template>
    <div class="rec-paginator">
        <md-button v-if="hasFirstLast && (currentPage-threshold > 1)"
                   class="md-raised md-primary"
                   @click="onFirstPage()">
            <i class="fas fa-angle-double-left"></i>
        </md-button>

        <md-button v-if="hasPrevNext && (currentPage-threshold > 1)"
                   class="md-raised md-primary"
                   @click="onPreviousPage()">
            <i class="fas fa-angle-left"></i>
        </md-button>

        <md-button v-if="currentPage-threshold > 1" class="md-flat">
            ...
        </md-button>

        <md-button v-for="(page,index) in pagesToDisplay"
                   :key="index"
                   class="md-raised"
                   :class="page === currentPage ? 'md-accent' : 'md-primary'"
                   @click="onChangePage(page)">
            {{page}}
        </md-button>

        <md-button v-if="currentPage+threshold < lastPage" class="md-flat">
            ...
        </md-button>

        <md-button v-if="hasPrevNext && (currentPage+threshold < lastPage)"
                   class="md-raised md-primary"
                   @click="onNextPage()">
            <i class="fas fa-angle-right"></i>
        </md-button>

        <md-button v-if="hasFirstLast && (currentPage+threshold < lastPage)"
                   class="md-raised md-primary"
                   @click="onLastPage()">
            <i class="fas fa-angle-double-right"></i>
        </md-button>
    </div>
</template>

<script>
export default {
  name: 'RecPaginator',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
    threshold: {
      type: Number,
      default: 2,
    },
    hasPrevNext: {
      type: Boolean,
      default: true,
    },
    hasFirstLast: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    pagesToDisplay() {
      const pages = [];
      for (let i = this.currentPage - this.threshold; i < this.currentPage; i += 1) {
        if (i > 0) pages.push(i);
      }
      for (let j = this.currentPage; j <= this.lastPage; j += 1) {
        if (j > this.currentPage + this.threshold) break;
        pages.push(j);
      }
      return pages;
    },
  },
  methods: {
    onFirstPage() {
      this.$emit('onFirstPage');
    },
    onPreviousPage() {
      this.$emit('onPreviousPage');
    },
    onChangePage(data) {
      this.$emit('onChangePage', data);
    },
    onNextPage() {
      this.$emit('onNextPage');
    },
    onLastPage() {
      this.$emit('onLastPage');
    },
  },
};
</script>

<style lang="scss">
    .rec-paginator {
    text-align: right;
        .md-button {
            margin: 0;
            padding: 0;
            width: 2rem;
            min-width: 2rem;
            max-width: 2rem;
            height: 2rem;
            min-height: 2rem;
            max-height: 2rem;
            line-height: 2rem;
            font-size: 1rem;
            border-radius: 2rem;
            margin-right: .5rem;
        }
    }
</style>
