<template>
  <div>
    <md-dialog :md-active.sync="active">
      <md-dialog-title>{{ $t('userTopTabs.mostPopularSiteTitle') }}</md-dialog-title>
      {{selectedSite}}

      <md-dialog-content>
        <md-autocomplete :v-model="selectedSite ? selectedSite.address : selectedSite" :value="selectedSite ? selectedSite.address : selectedSite"
                         :md-options="sites" @md-selected="selectSite" @md-changed="getSites" @md-opened="getSites" :md-fuzzy-search="false">
          <label>{{ $t('userTopTabs.sitetitle') }}</label>
          <template slot="md-autocomplete-item" slot-scope="{ item }">
            <md-highlight-text :md-term="item.address">{{ item.address }}</md-highlight-text>
          </template>

          <template slot="md-autocomplete-empty" slot-scope="{ term }">
            No sites matching "{{ term }}" were found.
          </template>
        </md-autocomplete>
        <md-field>
          <label>{{ $t('userTopTabs.position') }}</label>
          <md-input v-model="position" type="number" required></md-input>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="onClose()">{{ $t('userTopTabs.close') }}</md-button>
        <md-button class="md-primary" @click="addNewSite()">{{ $t('userTopTabs.save') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import {
  FETCH_SITES,
} from '@/store/modules/admin/resources/sites/sitesActions.type';
import {
  ADD_NEW_MOST_POPULAR_SITE,
  FETCH_MOST_POPULAR_SITES,
} from '@/store/modules/admin/resources/mostPopularSites/mostPopularSitesActions.type';

export default {
  name: 'MostPopularSiteModal',
  props: {
    active: Boolean,
  },
  data: () => ({
    site_id: null,
    selectedSite: null,
    sites: [],
    position: null,
  }),
  methods: {
    onClose() {
      this.$emit('onClose', false);
    },
    selectSite(item) {
      this.site_id = item.id;
    },
    getSites(searchTerm) {
      this.sites = window.setTimeout(() => {
        const params = {
          search: searchTerm,
        };
        this.$store.dispatch(FETCH_SITES, params).then((response) => {
          this.sites = response.data.data;
          this.$log.info('FETCH_SITES', response);
        }).catch((error) => {
          this.$log.error('FETCH_SITES', error);
        });
      }, 10);
    },
    fetchMostPopularSites() {
      const params = {
        page: 1,
        perPage: 50,
      };
      this.$store.dispatch(FETCH_MOST_POPULAR_SITES, params)
        .then((response) => {
          this.$log.info('FETCH_MOST_POPULAR_SITES', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_MOST_POPULAR_SITES', error);
        });
    },
    addNewSite() {
      const payload = {
        site_id: this.site_id ?? null,
        // eslint-disable-next-line radix
        position: parseInt(this.position) ?? 1,
      };
      this.$store.dispatch(ADD_NEW_MOST_POPULAR_SITE, payload)
        .then((response) => {
          this.$log.info(ADD_NEW_MOST_POPULAR_SITE, payload);
          this.fetchMostPopularSites();
          this.onClose();
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(ADD_NEW_MOST_POPULAR_SITE, error);
          this.$toasted.show(error, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          if (error.status === 'validation_error' && error.errors) {
            this.errors = error.errors;
          }
        });
    },
  },
  watch: {
    active: {
      handler(val) {
        this.active = val;
      },
    },
  },
};
</script>
<style>
.md-dialog-container {
  width: 50%;
}
.md-menu-content-bottom-start {
  z-index: 9999;
}
</style>
