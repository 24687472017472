<template>
  <md-card>
    <md-card-header>
      <div class="md-layout md-alignment-center-left listing-table-header">
        <div class="md-layout-item md-size-20 table-header-item">
          <h1 class="md-title">Unlisted Sites</h1>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-field>
            <label for="itemsPerPage">Display</label>
            <md-select :value="itemsPerPage"
                       name="itemsPerPage"
                       id="itemsPerPage"
                       @md-selected="refreshList"
                       md-dense>
              <md-option value="25">25</md-option>
              <md-option value="50">50</md-option>
              <md-option value="100">100</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-20 table-header-item">
          <md-field>
            <label for="sortBy">Sort by</label>
            <md-select :value="unlistedSitesActiveSort.code"
                       name="sortBy"
                       id="sortBy"
                       @md-selected="sortFn"
                       md-dense>
              <md-option v-for="sortField in unlistedSitesSortFields"
                         :key="sortField.code"
                         :value="sortField.code">
                {{ sortField.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-30 table-header-item">
          <md-field :md-clearable="true" @md-clear="clearsearch">
            <label for="search">Search...</label>
            <md-input name="search" id="search" v-model="searchInput"
                      :value="unlistedSitesSearch"
                      @keyup="searchFn">

            </md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" @click="search(searchInput)">
            Search
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100 md-small-size-100 md-xsmall-size-100">
          <listing-page :items="unlistedSitesItems"
                        :singleRouteName="'adminUnlistedSiteSingle'"
                        :columns="columns"
                        @onRemove="onRemove">
          </listing-page>
        </div>
      </div>
    </md-card-content>

    <md-card-actions>
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          <p>Showing {{ unlistedSitesFrom }} to {{ unlistedSitesTo }} of {{ unlistedSitesTotal }} Unlisted Sites</p>
        </div>
        <div class="md-layout-item md-size-50">
          <rec-paginator :current-page="unlistedSitesCurrentPage"
                         :last-page="unlistedSitesLastPage"
                         :threshold="2"
                         :has-prev-next="true"
                         :has-first-last="true"
                         @onFirstPage="onFirstPage"
                         @onPreviousPage="onPreviousPage"
                         @onNextPage="onNextPage"
                         @onLastPage="onLastPage"
                         @onChangePage="onChangePage">

          </rec-paginator>
        </div>
      </div>
    </md-card-actions>
  </md-card>
</template>

<script>
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RecPaginator from '@/components/pagination/RecPaginator.vue';
import {
  FETCH_UNLISTED_SITES,
  UPDATE_ACTIVE_SORT_FIELD_UNLISTED_SITES,
  UPDATE_SEARCH_UNLISTED_SITES,
  CLEAR_SEARCH_UNLISTED_SITES,
  DELETE_UNLISTED_SITE,
} from '@/store/modules/admin/resources/unlistedSites/unlistSitesActions.type';

export default {
  name: 'UnlistedSites',
  components: {
    ListingPage,
    RecPaginator,
  },
  data: () => ({
    columns: [
      {
        name: 'site_id',
        label: 'Site ID',
      },
      {
        name: 'domain',
        label: 'Domain Name',
      },
      {
        name: 'url',
        label: 'URL',
      },
      {
        name: 'username',
        label: 'Username',
      },
      {
        name: 'user_id',
        label: 'User ID',
      },
      {
        name: 'flagged_reason',
        label: 'Reported',
      },
    ],
    searchInput: null,
  }),
  computed: {
    unlistedSitesItems() {
      return this.$store.getters.unlistedSitesItems;
    },
    itemsPerPage() {
      return this.$store.getters.unlistedSitesPerPage;
    },
    unlistedSitesCurrentPage() {
      return this.$store.getters.unlistedSitesCurrentPage;
    },
    unlistedSitesLastPage() {
      return this.$store.getters.unlistedSitesLastPage;
    },
    unlistedSitesFrom() {
      return this.$store.getters.unlistedSitesFrom;
    },
    unlistedSitesTo() {
      return this.$store.getters.unlistedSitesTo;
    },
    unlistedSitesTotal() {
      return this.$store.getters.unlistedSitesTotal;
    },
    unlistedSitesSortFields() {
      return this.$store.getters.unlistedSitesSortFields;
    },
    unlistedSitesActiveSort() {
      return this.$store.getters.unlistedSitesActiveSort;
    },
    unlistedSitesSearch() {
      return this.$store.getters.unlistedSitesSearch;
    },
  },
  mounted() {
    this.initUnlistedSites();
  },
  methods: {
    initUnlistedSites() {
      const params = {
        page: 1,
        perPage: this.itemsPerPage,
      };
      this.fetchUnlistedSites(params);
    },
    fetchUnlistedSites(params) {
      this.$store.dispatch(FETCH_UNLISTED_SITES, params).then((response) => {
        this.$log.info('FETCH_UNLISTED_SITES', response);
      }).catch((error) => {
        this.$log.error('FETCH_UNLISTED_SITES', error);
      });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.itemsPerPage,
      };
      this.fetchSites(params);
    },
    onPreviousPage() {
      const params = {
        page: this.unlistedSitesCurrentPage - 1,
        perPage: this.itemsPerPage,
      };
      this.fetchUnlistedSites(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.itemsPerPage,
      };
      this.fetchUnlistedSites(params);
    },
    onNextPage() {
      const params = {
        page: this.unlistedSitesCurrentPage + 1,
        perPage: this.itemsPerPage,
      };
      this.fetchUnlistedSites(params);
    },
    onLastPage() {
      const params = {
        page: this.unlistedSitesLastPage,
        perPage: this.itemsPerPage,
      };
      this.fetchUnlistedSites(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchUnlistedSites(params);
    },
    clearsearch() {
      this.$store.dispatch(CLEAR_SEARCH_UNLISTED_SITES).then(() => {
        const params = {
          page: 1,
          perPage: this.itemsPerPage,
        };
        this.fetchUnlistedSites(params);
      }).catch((error) => {
        this.$log.error('CLEAR_SEARCH_UNLISTED_SITES', error);
      });
    },
    searchFn(data) {
      if (data.key === 'Enter') {
        const searchValue = data.target.value;
        this.search(searchValue);
      }
    },
    search(searchValue) {
      if (!searchValue || searchValue === '') {
        this.$store.dispatch(CLEAR_SEARCH_UNLISTED_SITES).then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchUnlistedSites(params);
        }).catch((error) => {
          this.$log.error('CLEAR_SEARCH_UNLISTED_SITES', error);
        });
      } else if (searchValue !== '') {
        this.$store.dispatch(UPDATE_SEARCH_UNLISTED_SITES, searchValue).then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchUnlistedSites(params);
        }).catch((error) => {
          this.$log.error('UPDATE_SEARCH_UNLISTED_SITES', error);
        });
      }
    },
    sortFn(data) {
      this.$store.dispatch(UPDATE_ACTIVE_SORT_FIELD_UNLISTED_SITES, data).then(() => {
        const params = {
          page: 1,
          perPage: this.itemsPerPage,
        };
        this.fetchUnlistedSites(params);
      }).catch((error) => {
        this.$log.error('UPDATE_ACTIVE_SORT_FIELD_UNLISTED_SITES', error);
      });
    },
    onRemove(item) {
      this.$store.dispatch(DELETE_UNLISTED_SITE, item.id)
        .then((response) => {
          this.initUnlistedSites();
          this.$log.info('DELETE_UNLISTED_SITE', response);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('DELETE_UNLISTED_SITE', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
  },
};
</script>

<style lang="scss">
.listing-table-header {
  .table-header-item {
    padding: 0.5rem;
    .md-field {
      margin: 0;
    }
    .create-button {
      float: right;
    }
  }
}

.md-card-actions, .md-card-content, .md-card-header {
  padding: 1rem;
}
</style>
