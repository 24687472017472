<template>
  <div>
    <md-dialog :md-active.sync="active">
      <md-dialog-title>Add New Code</md-dialog-title>
      <md-dialog-content>
        <md-field>
          <label>Code</label>
          <md-input v-model="code" type="text" required></md-input>
        </md-field>
        <md-field>
          <label>Discount</label>
          <md-input v-model="discount" type="number" required></md-input>
        </md-field>
        <md-field>
          <label v-show="!plan">Select a plan *</label>
          <select required name="plan" v-model="plan" style=" outline: none; border: none; width:150%;">
              <option :key ="keyItem.id" v-for="keyItem in plans" :value="keyItem.id">{{keyItem.type}}</option>
          </select>
        </md-field>
        <md-field>
          <label>Start Date</label>
          <md-input v-model="from" type="date" required></md-input>
        </md-field>
        <md-field>
          <label>End Date</label>
          <md-input v-model="to" type="date"></md-input>
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="onClose()">{{ $t('userTopTabs.close') }}</md-button>
        <md-button class="md-primary" @click="addNewCode()">{{ $t('userTopTabs.save') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import {
  FETCH_CODES,
  ADD_CODE,
} from '@/store/modules/admin/resources/subscriptionCodes/codesActions.type';


export default {
  name: 'CellModal',
  props: {
    active: Boolean,
    plans: Array,
  },
  data: () => ({
    plan: null,
    value: null,
    from: null,
    to: null,
    discount: null,
  }),

  methods: {
    onClose() {
      this.$emit('onClose', false);
    },
    fetchCodes(params) {
      this.$store.dispatch(FETCH_CODES, params)
        .then((response) => {
          this.plans = response.data.plans;
          this.$log.info('FETCH_CODES', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_CODES', error);
        });
    },
    addNewCode() {
      if (!this.validatModalData()) {
        this.showErrorMsg(this.$t('missingValue'));
        return;
      }
      const payload = {
        code: this.code,
        from: this.from,
        to: this.to,
        discount: this.discount,
        plan_id: this.plan,
      };
      this.$store.dispatch(ADD_CODE, payload)
        .then((response) => {
          this.onClose();
          this.fetchCodes();
          this.clearModalData();
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error(ADD_CODE, error);
          this.showErrorMsg(error);
        });
    },
    validatModalData() {
      if (this.code == null || this.discount == null || this.plan == null || this.from == null) {
        return false;
      }
      return true;
    },
    clearModalData() {
      this.code = null;
      this.to = null;
      this.from = null;
      this.discount = null;
      this.plan = null;
    },
    showErrorMsg(msg) {
      this.$toasted.show(msg, {
        type: 'error',
        position: 'top-right',
        duration: 5000,
        icon: 'alert',
      });
    },
  },
  watch: {
    active: {
      handler(val) {
        this.active = val;
      },
    },
  },
};
</script>
<style>
.md-dialog-container {
  width: 50%;
}
.md-menu-content-bottom-start {
  z-index: 9999;
}
input[type=date]::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
}
</style>
