<template>
    <md-table class="amf-listing-table" v-model="items">
        <md-table-empty-state md-label="No data"
                              :md-description="`There is nothing to display on this table.`">
        </md-table-empty-state>

        <md-table-row slot="md-table-row"
                      slot-scope="{ item }"
                      :class="getClass(item)"
                      md-selectable="single">

            <md-table-cell md-label="ID" md-numeric :md-sort-by="'id'">
                <b>{{item.id}}</b>
            </md-table-cell>

            <md-table-cell v-for="column in columns"
                           :key="column.name"
                           :md-label="column.label"
                           :md-sort-by="column.name">
                <img alt="AMF" v-if="column.name === 'public_path'" :src="item['public_path']">
                <img alt="AMF" v-else-if="column.name === 'iconpath'" :src="item['iconpath']">
                <router-link v-else-if="column.name === 'site_id' && item['site_id'] !== 0"
                             tag="a"
                             :to="{ name: 'adminSiteSingle', params: { id: item['site_id'] }}"
                             :class="'md-flat md-primary'">
                  <i class="fa fa-link"></i> {{item['site_id']}}
                </router-link>
                <router-link v-else-if="column.name === 'user_id' && item['user_id'] !== 0"
                             tag="a"
                             :to="{ name: 'adminUserSingle', params: { id: item['user_id'] }}"
                             :class="'md-flat md-primary'">
                  <i class="fa fa-link"></i> {{item['user_id']}}
                </router-link>
                <span v-else-if="column.name === 'user_id' && item['user_id'] === 0">not linked</span>
                <a v-else-if="column.name === 'address'" :href="'https://'+item['address']" target="_blank">
                  <i class="fa fa-link"></i> {{item['address']}}
                </a>
                <a v-else-if="column.name === 'domain'" :href="'https://'+item['domain']" target="_blank">
                  <i class="fa fa-link"></i> {{item['domain']}}
                </a>
                <span v-else-if="column.name === 'disabled' && item['disabled'] === 1">DISABLED</span>
                <span v-else-if="column.name === 'disabled' && item['disabled'] === 0">ENABLED</span>
                <span v-else-if="column.name === 'next_payment_date'">{{nextPaymentDateOrCanceledDate(item)}}</span>
                <span v-else v-html="item[column.name]"></span>
            </md-table-cell>

            <md-table-cell md-label="Actions">
                <router-link tag="md-button"
                             :to="{ name: singleRouteName, params: { id: item.id }}"
                             :class="'md-icon-button md-dense md-flat md-primary'">
                  <md-tooltip md-direction="top">Edit</md-tooltip>
                  <md-icon><i class="far fa-edit"></i></md-icon>
                </router-link>

                <md-button class="md-icon-button md-dense md-flat md-primary" @click="onRemove(item)">
                  <md-tooltip md-direction="top">Delete Permanently</md-tooltip>
                  <md-icon><i class="far fa-trash-alt"></i></md-icon>
                </md-button>

                <md-button v-if="isUploadedWebsitePage" class="md-icon-button md-dense md-flat md-primary" @click="onMarkOk(item)">
                  <md-tooltip md-direction="top">Marked as Ok</md-tooltip>
                  <md-icon><i class="far fa-check-circle"></i></md-icon>
                </md-button>

                <md-button v-if="isEditorTokensPage" class="md-icon-button md-dense md-flat md-primary" @click="unfetchUnlistedSites(item)">
                  <md-tooltip md-direction="top">Unfetch Unlisted sites by this token</md-tooltip>
                  <md-icon><i class="fas fa-undo"></i></md-icon>
                </md-button>

            </md-table-cell>

        </md-table-row>
    </md-table>
</template>

<script>

export default {
  name: 'ListingPage',
  props: {
    items: {
      type: Array,
      default() { return []; },
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    singleRouteName: {
      type: String,
      required: true,
    },
  },
  methods: {
    onRemove(item) {
      this.$emit('onRemove', item);
    },
    onMarkOk(item) {
      this.$emit('onMarkOk', item);
    },
    unfetchUnlistedSites(item) {
      this.$emit('unfetchUnlistedSites', item);
    },
    getClass(item) {
      if (item.disabled === 0) return 'item-enabled';
      if (item.disabled === 1) return 'item-disabled';
      return 'admin-listing-row';
    },
    nextPaymentDateOrCanceledDate(item) {
      if (item.next_payment_date) {
        return item.next_payment_date;
      }

      return item.end_grace_date;
    },
  },
  computed: {
    currentRouteName() {
      return this.$i18n.t(`${this.$route.name}`);
    },
    isUploadedWebsitePage() {
      return this.currentRouteName === 'adminFailedUploadedWebsitesListing' || this.currentRouteName === 'adminUploadedWebsitesListing';
    },
    isEditorTokensPage() {
      return this.currentRouteName === 'adminEditorTokens';
    },
  },
};
</script>

<style lang="scss">
    @import '@/assets/scss/layout/listing-page.scss';
</style>
