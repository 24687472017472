<template>
  <md-card>
    <md-card-header>
      <div class="md-layout md-alignment-center-left listing-table-header">
        <div class="md-layout-item md-size-20 table-header-item">
          <h1 class="md-title">Sites</h1>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-field>
            <label for="itemsPerPage">Display</label>
            <md-select :value="itemsPerPage" name="itemsPerPage" id="itemsPerPage" @md-selected="refreshList" md-dense>
              <md-option value="25">25</md-option>
              <md-option value="50">50</md-option>
              <md-option value="100">100</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-20 table-header-item">
          <md-field>
            <label for="sortBy">Sort by</label>
            <md-select :value="sitesActiveSort.code" name="sortBy" id="sortBy" @md-selected="sortFn" md-dense>
              <md-option v-for="sortField in sitesSortFields" :key="sortField.code" :value="sortField.code">
                {{ sortField.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-30 table-header-item">
          <md-field :md-clearable="true" @md-clear="clearsearch">
            <label for="search">Search...</label>
            <md-input name="search" id="search" v-model="searchInput" :value="sitesSearch" @keyup="searchFn">

            </md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" @click="search(searchInput)">
            Search
          </md-button>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" :to="{ name: 'adminSiteSingle', params: { id: null } }">
            Create
          </md-button>
        </div>
      </div>

      <div class="md-layout md-gutter md-alignment-center-left listing-table-header">
        <div class="md-layout-item md-size-10 table-header-item">
          <h1 class="md-title">Filters:</h1>
        </div>

        <div class="md-layout-item md-size-15 table-header-item">
          <md-checkbox v-model="notLinkedFilter">Not Linked</md-checkbox>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100 md-small-size-100 md-xsmall-size-100">
          <listing-page :items="sitesItems" :singleRouteName="'adminSiteSingle'" :columns="columns" @onRemove="onRemove">
          </listing-page>
        </div>
      </div>
    </md-card-content>

    <md-card-actions>
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          Showing {{ sitesFrom }} to {{ sitesTo }} of {{ sitesTotal }} Sites
        </div>
        <div class="md-layout-item md-size-50">
          <rec-paginator :current-page="sitesCurrentPage" :last-page="sitesLastPage" :threshold="2" :has-prev-next="true"
            :has-first-last="true" @onFirstPage="onFirstPage" @onPreviousPage="onPreviousPage" @onNextPage="onNextPage"
            @onLastPage="onLastPage" @onChangePage="onChangePage">

          </rec-paginator>
        </div>
      </div>
    </md-card-actions>
  </md-card>
</template>

<script>
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RecPaginator from '@/components/pagination/RecPaginator.vue';
import {
  CLEAR_SEARCH_SITES,
  FETCH_SITES,
  UPDATE_ACTIVE_SORT_FIELD_SITES,
  UPDATE_SEARCH_SITES,
  TOGGLE_NOT_LINKED_FILTER,
} from '@/store/modules/admin/resources/sites/sitesActions.type';
import { DELETE_SITE } from '@/store/modules/admin/single/site/siteActions.type';

export default {
  name: 'Sites',
  components: {
    ListingPage,
    RecPaginator,
  },
  data: () => ({
    columns: [
      {
        name: 'address',
        label: 'Address',
      },
      {
        name: 'public_path',
        label: 'Site Image',
      },
      {
        name: 'tab_cells_count',
        label: '#Tab Cells',
      },
      {
        name: 'unlisted_sites_count',
        label: '#Unlisted Sites',
      },
      {
        name: 'favorite_site',
        label: 'Favorite Site',
      },
    ],
    boolean: true,
    searchInput: null,
  }),
  computed: {
    sitesItems() {
      return this.$store.getters.sitesItems;
    },
    itemsPerPage() {
      return this.$store.getters.sitesPerPage;
    },
    sitesCurrentPage() {
      return this.$store.getters.sitesCurrentPage;
    },
    sitesLastPage() {
      return this.$store.getters.sitesLastPage;
    },
    sitesFrom() {
      return this.$store.getters.sitesFrom;
    },
    sitesTo() {
      return this.$store.getters.sitesTo;
    },
    sitesTotal() {
      return this.$store.getters.sitesTotal;
    },
    sitesSortFields() {
      return this.$store.getters.sitesSortFields;
    },
    sitesActiveSort() {
      return this.$store.getters.sitesActiveSort;
    },
    sitesSearch() {
      return this.$store.getters.sitesSearch;
    },
    notLinkedFilter: {
      get() {
        return this.$store.getters.notLinkedFilter;
      },
      set() {
        this.$store.dispatch(TOGGLE_NOT_LINKED_FILTER);
        // TODO to add here also the refresh of fetch sites after the value of the filter changes
      },
    },
  },
  mounted() {
    this.initSites();
  },
  methods: {
    initSites() {
      const params = {
        page: this.sitesCurrentPage,
        perPage: this.itemsPerPage,
      };
      this.fetchSites(params);
    },
    fetchSites(params) {
      this.$store.dispatch(FETCH_SITES, params).then((response) => {
        this.$log.info('FETCH_SITES', response);
      }).catch((error) => {
        this.$log.error('FETCH_SITES', error);
      });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.itemsPerPage,
      };
      this.fetchSites(params);
    },
    onPreviousPage() {
      const params = {
        page: this.sitesCurrentPage - 1,
        perPage: this.itemsPerPage,
      };
      this.fetchSites(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.itemsPerPage,
      };
      this.fetchSites(params);
    },
    onNextPage() {
      const params = {
        page: this.sitesCurrentPage + 1,
        perPage: this.itemsPerPage,
      };
      this.fetchSites(params);
    },
    onLastPage() {
      const params = {
        page: this.sitesLastPage,
        perPage: this.itemsPerPage,
      };
      this.fetchSites(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchSites(params);
    },
    clearsearch() {
      this.$store.dispatch(CLEAR_SEARCH_SITES).then(() => {
        const params = {
          page: 1,
          perPage: this.itemsPerPage,
        };
        this.fetchSites(params);
      }).catch((error) => {
        this.$log.error('CLEAR_SEARCH_SITES', error);
      });
    },
    searchFn(data) {
      if (data.key === 'Enter') {
        const searchValue = data.target.value;
        this.search(searchValue);
      }
    },
    search(searchValue) {
      if (!searchValue || searchValue === '') {
        // clear search
        this.$store.dispatch(CLEAR_SEARCH_SITES).then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchSites(params);
        }).catch((error) => {
          this.$log.error('CLEAR_SEARCH_SITES', error);
        });
      } else if (searchValue !== '') {
        this.$store.dispatch(UPDATE_SEARCH_SITES, searchValue).then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchSites(params);
        }).catch((error) => {
          this.$log.error('UPDATE_SEARCH_SITES', error);
        });
      }
    },
    sortFn(data) {
      this.$store.dispatch(UPDATE_ACTIVE_SORT_FIELD_SITES, data).then(() => {
        const params = {
          page: 1,
          perPage: this.itemsPerPage,
        };
        this.fetchSites(params);
      }).catch((error) => {
        this.$log.error('UPDATE_ACTIVE_SORT_FIELD_SITES', error);
      });
    },
    onRemove(item) {
      this.$store.dispatch(DELETE_SITE, item.id)
        .then((response) => {
          this.initSites();
          this.$log.info('DELETE_SITE', response);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('DELETE_SITE', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
  },
};
</script>

<style lang="scss">
.listing-table-header {
  .table-header-item {
    padding: 0.5rem;

    .md-field {
      margin: 0;
    }

    .create-button {
      float: right;
    }
  }
}

.md-card-actions,
.md-card-content,
.md-card-header {
  padding: 1rem;
}
</style>
