<template>
  <md-card>
    <md-card-header>
      <div class="md-layout md-alignment-center-left listing-table-header">
        <div class="md-layout-item md-size-20 table-header-item">
          <h1 class="md-title">Subscription Codes</h1>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-field>
            <label for="itemsPerPage">Display</label>
            <md-select :value="itemsPerPage" name="itemsPerPage" id="itemsPerPage" @md-selected="refreshList" md-dense>
              <md-option value="25">25</md-option>
              <md-option value="50">50</md-option>
              <md-option value="100">100</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-20 table-header-item">
          <md-field>
            <label for="sortBy">Sort by</label>
            <md-select :value="activeSort.code" name="sortBy" id="sortBy" @md-selected="sortFn" md-dense>
              <md-option v-for="sortField in sortFields" :key="sortField.code" :value="sortField.code">
                {{ sortField.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-30 table-header-item">
          <md-field :md-clearable="true" @md-clear="clearsearch">
            <label for="search">Search...</label>
            <md-input name="search" id="search" v-model="searchInput" :value="itemsSearch" @keyup="searchFn">
            </md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" @click="search(searchInput)">
            Search
          </md-button>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" @click="create">
            Create
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <listing-page :items="items" :singleRouteName="'adminSubscriptionCodePageSingle'" :columns="columns"
            @onRemove="onRemove" :active="active">
          </listing-page>
        </div>
      </div>
    </md-card-content>

    <md-card-actions>
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          Showing {{ from }} to {{ to }} of {{ total }}
        </div>
        <div class="md-layout-item md-size-50">
          <rec-paginator :current-page="currentPage" :last-page="lastPage" :threshold="2" :has-prev-next="true"
            :has-first-last="true" @onFirstPage="onFirstPage" @onPreviousPage="onPreviousPage" @onNextPage="onNextPage"
            @onLastPage="onLastPage" @onChangePage="onChangePage">

          </rec-paginator>
        </div>
      </div>
      <CodeModal @onClose="onClose" :plans="allPlans" :active="active"></CodeModal>
    </md-card-actions>
  </md-card>
</template>

<script>
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RecPaginator from '@/components/pagination/RecPaginator.vue';
import CodeModal from '@/components/modals/CodeModal.vue';

import {
  FETCH_CODES,
  CLEAR_SEARCH_CODES,
  UPDATE_SEARCH_CODES,
  UPDATE_ACTIVE_SORT_FIELD_CODES,
  DELETE_CODE,
} from '@/store/modules/admin/resources/subscriptionCodes/codesActions.type';


export default {
  name: 'SubscriptionCodes',
  components: {
    ListingPage,
    RecPaginator,
    CodeModal,
  },
  data: () => ({
    columns: [
      {
        name: 'code',
        label: 'Code',
      },
      {
        name: 'from',
        label: 'Start Date',
      },
      {
        name: 'to',
        label: 'End Date',
      },
      {
        name: 'plan',
        label: 'Plan',
      },
      {
        name: 'discount',
        label: 'Discount',
      },
      {
        name: 'subscription_cost',
        label: 'Subscription Cost',
      },
    ],
    active: false,
    plans: null,
    searchInput: null,
  }),
  computed: {
    allPlans() {
      return this.plans;
    },
    items() {
      return this.$store.getters.subscriptionCodesItems;
    },
    itemsPerPage() {
      return this.$store.getters.itemsPerPage;
    },
    currentPage() {
      return this.$store.getters.subscriptionCodesCurrentPage;
    },
    lastPage() {
      return this.$store.getters.subscriptionCodesLastPage;
    },
    from() {
      return this.$store.getters.subscriptionCodesFrom;
    },
    to() {
      return this.$store.getters.subscriptionCodesTo;
    },
    total() {
      return this.$store.getters.subscriptionCodesTotal;
    },
    sortFields() {
      return this.$store.getters.subscriptionCodesSortFields;
    },
    activeSort() {
      return this.$store.getters.subscriptionCodesActiveSort;
    },
    itemsSearch() {
      return this.$store.getters.subscriptionCodesSearch;
    },
  },
  mounted() {
    this.initCodes();
  },
  methods: {
    onClose() {
      this.active = false;
      const params = {
        page: this.currentPage,
        perPage: this.itemsPerPage,
      };
      this.fetchCodes(params);
    },
    initCodes() {
      const params = {
        page: this.currentPage,
        perPage: this.itemsPerPage,
      };
      this.fetchCodes(params);
    },
    fetchCodes(params) {
      this.$store.dispatch(FETCH_CODES, params)
        .then((response) => {
          this.plans = response.data.plans;
          this.$log.info('FETCH_CODES', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_CODES', error);
        });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.itemsPerPage,
      };
      this.fetchCodes(params);
    },
    onPreviousPage() {
      const params = {
        page: this.currentPage - 1,
        perPage: this.itemsPerPage,
      };
      this.fetchCodes(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.itemsPerPage,
      };
      this.fetchCodes(params);
    },
    onNextPage() {
      const params = {
        page: this.currentPage + 1,
        perPage: this.itemsPerPage,
      };
      this.fetchCodes(params);
    },
    onLastPage() {
      const params = {
        page: this.lastPage,
        perPage: this.itemsPerPage,
      };
      this.fetchCodes(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchCodes(params);
    },
    clearsearch() {
      this.$store.dispatch(CLEAR_SEARCH_CODES)
        .then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchCodes(params);
        })
        .catch((error) => {
          this.$log.error('CLEAR_SEARCH_CODES', error);
        });
    },
    searchFn(data) {
      if (data.key === 'Enter') {
        const searchValue = data.target.value;
        this.search(searchValue);
      }
    },
    search(searchValue) {
      if (!searchValue || searchValue === '') {
        this.$store.dispatch(CLEAR_SEARCH_CODES)
          .then(() => {
            const params = {
              page: 1,
              perPage: this.itemsPerPage,
            };
            this.fetchCodes(params);
          })
          .catch((error) => {
            this.$log.error('CLEAR_SEARCH_CODES', error);
          });
      } else if (searchValue !== '') {
        this.$store.dispatch(UPDATE_SEARCH_CODES, searchValue)
          .then(() => {
            const params = {
              page: 1,
              perPage: this.itemsPerPage,
            };
            this.fetchCodes(params);
          })
          .catch((error) => {
            this.$log.error('UPDATE_SEARCH_CODES', error);
          });
      }
    },
    sortFn(data) {
      this.$store.dispatch(UPDATE_ACTIVE_SORT_FIELD_CODES, data)
        .then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchCodes(params);
        })
        .catch((error) => {
          this.$log.error('UPDATE_ACTIVE_SORT_FIELD_CODES', error);
        });
    },
    onRemove(item) {
      this.$store.dispatch(DELETE_CODE, item.id)
        .then((response) => {
          this.$log.info('DELETE_CODE', response);
          const params = {
            page: this.currentPage,
            perPage: this.itemsPerPage,
          };
          this.fetchCodes(params);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('DELETE_CODE', error);
        });
    },
    create() {
      this.active = false; // to open the modal even i closed it before by clicking outside
      this.active = true;
    },
  },
};
</script>

<style lang="scss">
.listing-table-header {
  .table-header-item {
    padding: 0.5rem;

    .md-field {
      margin: 0;
    }

    .create-button {
      float: right;
    }
  }
}

.md-card-actions,
.md-card-content,
.md-card-header {
  padding: 1rem;
}
</style>
