<template>
  <md-card>
    <md-card-header>
      <div class="md-layout md-alignment-center-left listing-table-header">
        <div class="md-layout-item md-size-20 table-header-item">
          <h1 class="md-title">Users</h1>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-field>
            <label for="itemsPerPage">Display</label>
            <md-select :value="itemsPerPage" name="itemsPerPage" id="itemsPerPage" @md-selected="refreshList" md-dense>
              <md-option value="25">25</md-option>
              <md-option value="50">50</md-option>
              <md-option value="100">100</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-20 table-header-item">
          <md-field>
            <label for="sortBy">Sort by</label>
            <md-select :value="usersActiveSort.code" name="sortBy" id="sortBy" @md-selected="sortFn" md-dense>
              <md-option v-for="sortField in usersSortFields" :key="sortField.code" :value="sortField.code">
                {{ sortField.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-30 table-header-item">
          <md-field :md-clearable="true" @md-clear="clearsearch">
            <label for="search">Search...</label>
            <md-input name="search" id="search" v-model="searchInput" :value="usersSearch" @keyup="searchFn">

            </md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" @click="search(searchInput)">
            Search
          </md-button>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-button class="md-accent md-raised create-button" :to="{ name: 'adminUserSingle', params: { id: null } }">
            Create
          </md-button>
        </div>
      </div>

      <div class="md-layout md-gutter md-alignment-center-left listing-table-header">
        <div class="md-layout-item md-size-10 table-header-item">
          <h1 class="md-title">Filters:</h1>
        </div>
        <div class="md-layout-item md-size-100">
          <md-checkbox v-for="plan in plans" :key="plan.id" v-model="plan.checked"
            @change="filterUsersAccordingSubscriptionPlan">{{ plan.type.replace("_", " ") }}</md-checkbox>
          <md-checkbox v-model="premiumChecked" @change="filterUsersAccordingSubscriptionPlan">Premium</md-checkbox>
        </div>
        <div class="md-layout-item md-size-100 table-header-item">
          <md-checkbox v-model="notConfirmedFilter">Not Confirmed</md-checkbox>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout" v-if="isLoading">
        <div class="md-layout-item md-size-100 spinner-container">
          <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
        </div>
      </div>

      <div class="md-layout" v-else>
        <div class="md-layout-item md-size-100 md-small-size-100 md-xsmall-size-100">
          <listing-page :items="usersItems" :singleRouteName="'adminUserSingle'" :columns="columns" @onRemove="onRemove">
          </listing-page>
        </div>
      </div>
    </md-card-content>

    <md-card-actions>
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          Showing {{ usersFrom }} to {{ usersTo }} of {{ usersTotal }} Users
        </div>
        <div class="md-layout-item md-size-50">
          <rec-paginator :current-page="usersCurrentPage" :last-page="usersLastPage" :threshold="2" :has-prev-next="true"
            :has-first-last="true" @onFirstPage="onFirstPage" @onPreviousPage="onPreviousPage" @onNextPage="onNextPage"
            @onLastPage="onLastPage" @onChangePage="onChangePage">

          </rec-paginator>
        </div>
      </div>
    </md-card-actions>
  </md-card>
</template>

<script>
import PaymentsService from '@/services/payments.service';
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RecPaginator from '@/components/pagination/RecPaginator.vue';
import loaderMixin from '@/mixins/loaderMixin';
import {
  FETCH_ADMIN_USERS,
  UPDATE_ACTIVE_SORT_FIELD_USERS,
  UPDATE_SEARCH_USERS,
  CLEAR_SEARCH_USERS,
  TOGGLE_NOT_CONFIRMED_FILTER,
} from '@/store/modules/admin/resources/users/usersActions.type';
import { DELETE_USER } from '@/store/modules/auth/authActions.type';

export default {
  name: 'Users',
  components: {
    ListingPage,
    RecPaginator,
  },
  mixins: [
    loaderMixin,
  ],
  data: () => ({
    plans: [],
    premiumChecked: false,
    searchInput: null,
    columns: [
      {
        name: 'username',
        label: 'Username',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'role',
        label: 'Role',
      },
      {
        name: 'user_tabs_count',
        label: '#Tabs',
      },
      {
        name: 'files_count',
        label: '#Files',
      },
      {
        name: 'plan',
        label: 'Plan',
      },
      {
        name: 'next_payment_date',
        label: 'Expired',
      },
      {
        name: 'deleted_at',
        label: 'Deleted',
      },
      {
        name: 'created_at',
        label: 'Created at',
      },
      {
        name: 'last_login',
        label: 'Last login',
      },
      {
        name: 'premium',
        label: 'Premium',
      },
      {
        name: 'ip',
        label: 'Ip',
      },
    ],
  }),
  computed: {
    notConfirmedFilter: {
      get() {
        return this.$store.getters.notConfirmedFilter;
      },
      set() {
        this.$store.dispatch(TOGGLE_NOT_CONFIRMED_FILTER);
      },
    },
    usersItems() {
      return this.$store.getters.usersItems;
    },
    itemsPerPage() {
      return this.$store.getters.usersPerPage;
    },
    usersCurrentPage() {
      return this.$store.getters.usersCurrentPage;
    },
    usersLastPage() {
      return this.$store.getters.usersLastPage;
    },
    usersFrom() {
      return this.$store.getters.usersFrom;
    },
    usersTo() {
      return this.$store.getters.usersTo;
    },
    usersTotal() {
      return this.$store.getters.usersTotal;
    },
    usersSortFields() {
      return this.$store.getters.usersSortFields;
    },
    usersActiveSort() {
      return this.$store.getters.usersActiveSort;
    },
    usersSearch() {
      return this.$store.getters.usersSearch;
    },
  },
  mounted() {
    this.initUsers();
    PaymentsService.getPlans().then((res) => {
      [this.plans] = res.data.data;
      this.plans.forEach((element) => {
        /* eslint no-param-reassign: "error" */
        element.checked = true;
      });
    });
  },
  methods: {
    filterUsersAccordingSubscriptionPlan() {
      const checkedPlansIds = this.plans.filter(plan => plan.checked).map(obj => obj.id);
      if (this.premiumChecked) {
        checkedPlansIds.push(-1);
      }

      const params = {
        page: this.usersCurrentPage,
        perPage: this.itemsPerPage,
        planIds: checkedPlansIds,
      };
      this.fetchUsers(params);
    },
    initUsers() {
      const params = {
        page: this.usersCurrentPage,
        perPage: this.itemsPerPage,
      };
      this.fetchUsers(params);
    },
    fetchUsers(params) {
      this.$store.dispatch(FETCH_ADMIN_USERS, params).then((response) => {
        this.$log.info('FETCH_ADMIN_USERS', response);
      }).catch((error) => {
        this.$log.error('FETCH_ADMIN_USERS', error);
      });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.itemsPerPage,
      };
      this.fetchUsers(params);
    },
    onPreviousPage() {
      const params = {
        page: this.usersCurrentPage - 1,
        perPage: this.itemsPerPage,
      };
      this.fetchUsers(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.itemsPerPage,
      };
      this.fetchUsers(params);
    },
    onNextPage() {
      const params = {
        page: this.usersCurrentPage + 1,
        perPage: this.itemsPerPage,
      };
      this.fetchUsers(params);
    },
    onLastPage() {
      const params = {
        page: this.usersLastPage,
        perPage: this.itemsPerPage,
      };
      this.fetchUsers(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchUsers(params);
    },
    clearsearch() {
      this.$store.dispatch(CLEAR_SEARCH_USERS).then(() => {
        const params = {
          page: 1,
          perPage: this.itemsPerPage,
        };
        this.fetchUsers(params);
      }).catch((error) => {
        this.$log.error('CLEAR_SEARCH_USERS', error);
      });
    },
    searchFn(data) {
      if (data.key === 'Enter') {
        const searchValue = data.target.value;
        this.search(searchValue);
      }
    },
    search(searchValue) {
      if (!searchValue || searchValue === '') {
        // clear search
        this.$store.dispatch(CLEAR_SEARCH_USERS).then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchUsers(params);
        }).catch((error) => {
          this.$log.error('CLEAR_SEARCH_USERS', error);
        });
      } else if (searchValue !== '') {
        this.$store.dispatch(UPDATE_SEARCH_USERS, searchValue).then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchUsers(params);
        }).catch((error) => {
          this.$log.error('UPDATE_SEARCH_USERS', error);
        });
      }
    },
    sortFn(data) {
      this.$store.dispatch(UPDATE_ACTIVE_SORT_FIELD_USERS, data).then(() => {
        const params = {
          page: 1,
          perPage: this.itemsPerPage,
        };
        this.fetchUsers(params);
      }).catch((error) => {
        this.$log.error('UPDATE_ACTIVE_SORT_FIELD_USERS', error);
      });
    },
    onRemove(item) {
      this.$store.dispatch(DELETE_USER, item.id)
        .then((response) => {
          this.initUsers();
          this.$log.info('DELETE_USER', response);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('DELETE_USER', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
  },
};
</script>

<style lang="scss">
.listing-table-header {
  .table-header-item {
    padding: 0.5rem;

    .md-field {
      margin: 0;
    }

    .create-button {
      float: right;
    }
  }
}

.md-card-actions,
.md-card-content,
.md-card-header {
  padding: 1rem;
}
</style>
